// Global variables app.js
var rackLimit,
    movement_casks = [],
    palletsScanned = [],
    last_incident = {}, // Used to prevent double scan of an incident / code combo
    previous_scan, // The last barcode scanned, if it was saved. Could be a cask ID or SWA barcode at least, if not others.
    firstCaskScanned = false,
    requestTimeout = parseInt((localStorage.getItem("settings/requestTimeout")) ? localStorage.getItem("settings/requestTimeout") : 60000),
    hideDoubleScanError = (localStorage.getVariable("settings/hideDoubleScanError") === "true");

/* globals showAlert, sendTransactions, storeTransaction, playSuccessSound, doesProgramSendTransactions,
isPickingProgram, isCaskOnTrailer, countTrailerCasks, deductTrailerContent, getProgramFields, getBarcodeFromBarrelId,
createBarcodeObject, updateAllocationCounter, isCaskValidInAllocation, getProgramTransactionCode, hasProgramParameter,
isTrailerFull, getProgramWarehouseType, updateTrailerContent, isLocationCapacityBreached, saveGenericTransaction */

/**
 *
 * @type {Object} VesselBarcodeType Enum defining types of barcode that can be scanned.
 * @property {String} caskId - A Cask Identifier, for Wood tracking or non SWA customers
 * @property {String} scotch - A SWA variant, usually in Scotland, containing Make, Fill Year, Rotation, Cask No
 * @property {String} tun - A Tun label
 * @property {String} pallet - A pallet label
 */

var VesselBarcodeType = Object.freeze({"caskId":"CID","scotch":"SWA","tun":"TUN","pallet":"PALLET"});
// todo can we refactor caskId to be CASKID so that it matches barcode format types?

function setLastIncident(barcode, caskId, incidentCode) {
	"use strict";
	last_incident = {
		barcode: barcode,
		caskId: caskId,
		incidentCode: String(incidentCode)
	};
}

function saveLabels(caskLabel, warehouseLabels) {
	"use strict";
	var labels = {};
	labels.caskLabel = caskLabel;
	// Process warehouse labels to save as an object
	labels.racked = {};
	labels.racked.bay = warehouseLabels[0].bay;
	labels.racked.rack = warehouseLabels[0].rack;
	labels.racked.level = warehouseLabels[0].level;
	labels.pallet = {};
	labels.pallet.bay = warehouseLabels[1].bay;
	labels.pallet.rack = warehouseLabels[1].rack;
	labels.pallet.level = warehouseLabels[1].level;
	localStorage.setObject("data/labels",labels);
}

function getLabels() {
  "use strict";
	return localStorage.getObject("data/labels");
}

/**
 * @description
 * @param array
 * @returns {Array|string|*}
 */
function arrayUnique(array) {
	"use strict";
	var a = array.concat();
	for(var i=0; i<a.length; ++i) {
		for(var j=i+1; j<a.length; ++j) {
			if(a[i] === a[j])
				a.splice(j--, 1);
		}
	}
	return a;
}

//Todo move these to the ui js, or get the containers to use new generic function
/**
 * @description Scrolls the Cask List Container "up" or "down"
 * @param {String} direction "up" or "down"
 */
function scrollList(direction) {
	"use strict";
  var amount = ((direction === "up") ? "-=150px" : "+=150px");
  $("#casklistcontainer").animate({
    scrollTop: amount
  });
}

/**
 * @description Scrolls the Allocation List Container "up" or "down"
 * @param {String} direction "up" or "down"
 */
function scrollAllocationList(direction) {
	"use strict";
  var amount = ((direction === "up") ? "-=150px" : "+=150px");
  $("#allocationcontainer").animate({
    scrollTop: amount
  });
}

/**
 * @description Searches a flat array for an item
 * @param {String|Number} searchterm
 * @param list
 * @returns {boolean} Returns true if the item is in the array
 */
function searchList(searchterm, list) {
	"use strict";
  // searches list to see if an item exists there.
  var result = list.indexOf(searchterm);
  return result >= 0;
}

/**
 * @description Searches an object array for an object where the key matches the given value
 * @param array
 * @param {String} key
 * @param {String|Number|Boolean} value
 * @returns {Number|null} Returns the index of the item in the array or null if not found.
 */
function searchListForItem(array, key, value) {
	"use strict";
  // searches array for key = value
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] === value) return i;
  }
  return null;
}

/**
 * @description Searches an object array for an object where the key matches the given value ignoring casing
 * @param array
 * @param {String} key
 * @param {String|Number|Boolean} value
 * @returns {Number|null} Returns the index of the item in the array or null if not found.
 */
function searchListForItemCaseInsensitive(array, key, value) {
	"use strict";
  // searches array for key = value
  for (var i = 0; i < array.length; i++) {
    if (array[i][key].toLowerCase() === value.toLowerCase()) return i;
  }
  return null;
}

/**
 * @description Searches an object array for an object where the key matches the given value.
 * @param array
 * @param {string} key
 * @param value
 * @returns {number} Returns the index of the item in the array or -1 if not found.
 */
function searchArrayForObject(array, key, value) {
	"use strict";
  for (var i = 0; i < array.length; i++) {
    if (array[i][key] === value) return i;
  }
  return -1;
}

/**
 * @description Searches for an object in a given array, based on a key.
 * @param array - The array to search through
 * @param key - The key to match the object on
 * @param value - the key value to match
 * @returns {*} Returns the object or null if not found.
 */
function findObjectInArray(array, key, value) {
	"use strict";
	for (var i=0; i< array.length; i++) {
		if(array[i][key] === value) return array[i];
	}
	return null;
}

/**
 * @description Sorts an array of objects by the given objects property
 * @param {String} property  - If begins "-" then reverse the order.
 * @returns {Function}
 */
function sortByProperty(property) {
	"use strict";
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
    return result * sortOrder;
  };
}

/**
 * @description Strips the scanner Prefix from a string to leave a valid string
 * @param {String} originalString
 * @returns {String}
 */
function stripScanningPrefix(originalString) {
	"use strict";
  var prefix = localStorage.getItem("settings/scanPrefix");
  if (prefix !== "") {
    return (originalString.substr(0, prefix.length) === prefix) ? originalString.substr(prefix.length): originalString;
  } else {
    return originalString;
  }
}

/**
 * @description Trims a string to the required length. The last 3 characters are lost for ...
 * @param {String} str
 * @param {number} length
 * @returns {String}
 */
function limitString(str, length) {
	"use strict";
  if (str.length > length) {
    return str.slice(0, length - 3) + "...";
  }
  else {
    return str;
  }
}

/**
 * @description Pads a number with leading 0s to fit the requred length format.
 * @param {Number} requiredLength
 * @param {string | Number} string Input to pad. Input is cast to a string.
 * @returns {string}
 */
function padNumber(requiredLength, string) {
	"use strict";
  // pad a number with leading 0s
  string = String(string);
  while (string.length < requiredLength) string = "0" + string;
  return string;
}

function padString(requiredLength, string) {
  "use strict";
  while (string.length < requiredLength) {
    string = string + " ";
  }
  return string;
}

/**
 * @description Checks the value is a number
 * @param value
 * @returns {boolean}
 */
function isNumeric(value) {
	"use strict";
  if (value === "") return false;
  return !isNaN(value);
}

function isNumericAndNotZero(value) {
	"use strict";
	if (value === "") return false;
	if (isNaN(value)) return false;
	return parseInt(value) !== 0;
}

/**
 * @description Validates the Make from the list of makes
 * @param {String} make
 * @returns {*}
 */
function validateMake(make) {
	"use strict";
  var makes = localStorage.getObject("data/makes");
  return searchListForItem(makes, 'makeCode', make);
}

/**
 * @description Find the make code that has been scanned and return it
 * @param {string} make
 * @returns {null|{makeCode: string, codeType: string, blendCode: string}}
 */
function getMake(make) {
  "use strict";
  var makes = localStorage.getObject("data/makes");
  return findObjectInArray(makes, "makeCode", make);
}

/**
 * @description Find the Make mappings where the blend code will match what is required.
 * @param {{makeCode: string, codeType: string, blendCode: string}} foundMake
 */
function getMatchingMakes(foundMake) {
  "use strict";
  var makes = localStorage.getObject("data/makes");
  switch (foundMake.codeType) {
    case "Blend":
      return makes.filter(function(el) {
        return el.blendCode === foundMake.makeCode; // Return the mappings where the blend code matched.
      });
    default:
      return makes.filter(function(el) {
        // return for a Make mapping where we want to use the blend code. Don't return self.
        // Also if it was a make mapping then we want to return similar make mappings.
        return (el.blendCode === foundMake.blendCode && el.makeCode !== foundMake.makeCode) ||
          (el.makeCode === foundMake.makeCode && el.blendCode !== foundMake.blendCode);
      });
  }

}

/**
 * @description Validates a scanned code is a valid barcode. valid barcode types are SWA, WTL, and WTLS for shorthand WTL.
 * @param {string} scannedCode
 * @param {string} scanInputField - The name of the field the scanned code has been entered from
 * @param {string|number} validationField - Additional information for any extra validation
 * @returns {*} False if not valid, The type as a string if valid.
 */
function isBarcodeValid(scannedCode, scanInputField, validationField) {
	"use strict";
	//TODO compare this with the isAlreadyScanned to check scanned validation
	// This method should then call above function rather than 2 sets of the same code.
	// Also we need to depreciate old scanning barcode techniques - look how horrible the code is..
  var barcodeType = "",
    barcodeMap = localStorage.getVariable("settings/classicBarcodeMapping").split(""),
    woodTrackingMap = localStorage.getVariable("settings/woodTrackingMap").split(""),
    woodTrackingPrefix = (localStorage.getItem("settings/woodTrackingPrefix")) ? localStorage.getItem("settings/woodTrackingPrefix") : "",
    woodTrackingTagLength = parseInt(localStorage.getItem("settings/woodTrackingTagLength")),
    validateWood = (localStorage.getItem("settings/woodTrackingValidation")) ? localStorage.getItem("settings/woodTrackingValidation") : "false",
    woodTrackingFormat = (localStorage.getItem("settings/woodTrackingFormat")) ? localStorage.getItem("settings/woodTrackingFormat") : "[0-9]",
    woodTrackingExpression = woodTrackingPrefix + "+" + woodTrackingFormat + "{" + (woodTrackingMap.length - woodTrackingPrefix.length) + "}",
    allowSWA = (localStorage.getItem("settings/useSWA") === "true"),
    allowWoodTracking = (localStorage.getItem("settings/useWTL") === "true");

  if (scannedCode.length === 0) {
    return false;
  }

  if (barcodeMap.length === scannedCode.length && allowSWA) {
    if (!isValidString(scannedCode, "[a-zA-Z0-9]{" + barcodeMap.length + "}")) {
      return false;
    }
    barcodeType = "SWA";
  } else if (woodTrackingMap.length === scannedCode.length && allowWoodTracking) {
    if (!isValidString(scannedCode, woodTrackingExpression)) {
      return false;
    }
    if (validateWood === "true") {
      if (scannedCode.substr(0, woodTrackingPrefix.length).toUpperCase() === woodTrackingPrefix) {
        barcodeType = "WTL";
      } else {
        return false;
      }
    } else {
      barcodeType = "WTL";
    }
  } else if (woodTrackingMap.length > scannedCode.length && allowWoodTracking) {
    scannedCode = woodTrackingPrefix + padNumber(woodTrackingMap.length - woodTrackingPrefix.length, scannedCode);
    if (!isValidString(scannedCode, woodTrackingExpression)) {
      return false;
    }
    if (woodTrackingMap.length === scannedCode.length) {
      barcodeType = "WTLS";
    } else {
      return false;
    }
  } else if (scannedCode.length === woodTrackingTagLength && allowWoodTracking) {
    scannedCode = scannedCode.substring(0, woodTrackingMap.length);
    if (!isValidString(scannedCode, woodTrackingExpression)) {
      return false;
    }
    if (validateWood === "true") {
      if (scannedCode.substr(0, woodTrackingPrefix.length).toUpperCase() === woodTrackingPrefix) {
        barcodeType = "WTLL";
      } else {
        return false;
      }
    } else {
      barcodeType = "WTLL";
    }
  } else {
    return false;
  }

  //Ignore fill location field at end
  //only if label is SWA
  //Using get barcode mapping we can create the length to check
  //Needs to be found using mapping field lengths and a substring of the barcode and previous barcodes.
  //This is flawed as it will only cater for removing from the end.
  //I.E. If the batch had length but was not validated, then the string would never match.
  //No customers at present deal with anything more complex.
  //If this were to change, the whole check system needs to change to check fields over baroode display fields.
  //TODO if we change history to store a scan rather than barcode then this should be changed too as we will then
  //be able to check fields appropriately - as for now we would have to repeatedly convert movement_casks[n] to data
  //and validate which would be much more work for the scanner to do between scans - and could possibly slow it down as
  //more and more is scanned.
  //TODO additionaly would be better to store the barcode object with the transaction code too
  //Checking for double scans and rescans could then check better as it could check code scanned + trcode
  //This would better catch incident scans and generated scans where they have to skip the movement_casks check
  //This can eventually lead to a double scan under certain circumstances
  //ADDING in conditions for custom format labels, to determine double scans etc
  if(barcodeType === "SWA"  && scanInputField !== "incidentBarcode" && scanInputField !== "generatedInput") {
    var mapping = getBarcodeMapping();
    var validMappingLength = mapping.batch.length +
      mapping.make.length + mapping.fillYear.length +
      mapping.rotation.length + mapping.caskNumber.length +
      mapping.fillLocation.length;

    for(var f=0; f<movement_casks.length; f++) {
      if(scannedCode.substring(0,validMappingLength) === movement_casks[f].substring(0,validMappingLength)) {
        barcodeType = "SCANNED";
        break;
      }
    }
  } else {
    //check it hasn't been scanned already. Exclude scans from the incident input
    if (searchList(scannedCode, movement_casks) && scanInputField !== "incidentBarcode" && scanInputField !== "generatedInput") {
      barcodeType = "SCANNED";
    }
  }

  if (scanInputField === "incidentBarcode") {
    if(last_incident.barcode === scannedCode || last_incident.caskid === scannedCode) {
        if(parseInt(last_incident.incidentcode) === parseInt(validationField)) {
            barcodeType = "DOUBLE"; // previous scan was the barcode just scanned and was incident
        }
    }
  } else {
    // "generatedInput" is for system generated transactions, which don't have risk of a double scan.
    if((previous_scan === scannedCode) && (scanInputField !== "generatedInput"))  {
        barcodeType = "DOUBLE"; // previous scan was the barcode just scanned
    }
  }
  return barcodeType;
}

/**
 * @description breaks up two barcodes and compares the vital SWA fields.
 * @param {String} barcodeOne
 * @param {String} barcodeTwo
 * @returns {boolean} Returns true if the SWA barcodes match.
 */
function doesSWABarcodesMatch(barcodeOne, barcodeTwo) {
  "use strict";
  var SWA1 = barcodeToData(barcodeOne),
    SWA2 = barcodeToData(barcodeTwo);

  if (SWA1.batch === SWA2.batch && SWA1.make === SWA2.make && SWA1.fillYear === SWA2.fillYear && SWA1.rotation === SWA2.rotation && SWA1.caskNumber === SWA2.caskNumber) {
    return true;
  }

  // If the normal matching has worked then the barcodes match exactly. This may not be the case if the barcode is for a make mapping or blend.
  // We might be looking at an allocation, where the data may not always match due to old barcodes being printed with make mapping data.
  // We need to get the make type to be sure and then continue assertions to see if it matches another way.
  // Check the other fields will still match before continuing.
  if (SWA1.batch === SWA2.batch && SWA1.fillYear === SWA2.fillYear && SWA1.rotation === SWA2.rotation && SWA1.caskNumber === SWA2.caskNumber) {
    var scannedMake = getMake(SWA1.make.trim());
    if (scannedMake && scannedMake.codeType !== "Name") {
      // The make scanned was either a Mapping or a Blend. We need to find associated records for that type.
      // First before we find more mappings we can check to see if the blend would match.
      if (scannedMake.codeType === "Mapping" && scannedMake.blendCode === SWA2.make.trim()) {
        // The matching barcode used the blend code and not the mapping, so is valid.
        // (For an allocation that could occur when use blend codes on allocation barcodes is on and an old mapping was scanned.)
        return true;
      }
      // If it was a blend we will want to find all the mappings that have that blend code.
      // If it was a make mapping we will want to find other make mappings where the blend code was the same.
      var alternatives = getMatchingMakes(scannedMake);
      for (var i=0; i< alternatives.length; i++) {
        if (alternatives[i].makeCode === SWA2.make.trim() || (alternatives[i].blendCode === SWA2.make.trim() && scannedMake.codeType === "Mapping")) {
          // The alternative make code has matched the make code on the barcode.
          // (for allocations this may happen when new blend codes are scanned and not output with the allocation. The allocation will show a make mapping).
          // (it could also occur if there is more than one make mapping for the blend. Scanning something other than the first make mapping will get here in code).
          return true;
        }
      }
    }
  }
  return false;
}

/**
 * @description Checks to see if a barcode has already been scanned
 * @param scannedCode {String} The barcode or Cask ID scanned
 * @param barcodeType {String} The type of barcode "SWA" or "WTL"
 * @param inputField {String} The input field the scan was made in
 * @param validationField {string|Number} variable that may be required for additional validation
 * @returns {boolean} True if the barcode has already been scanned
 */
function isAlreadyScanned(scannedCode, barcodeType, inputField, validationField) {
  "use strict";
  var scanned = false;
  // SWA Barcode check - By this point a barcode should be in standard format
  // Do not check for incidents or generated scans - these can be double scanned
  if (inputField !== "incidentBarcode" && inputField !== "generatedInput") {
    // First check for a double scan - this is easy
    if((previous_scan === scannedCode) && (inputField !== "generatedInput"))  {
      // use global hideDoubleScanError
      if(!hideDoubleScanError) {
        showAlert("This cask has already been scanned", {focus: inputField});
      }
      return true; // Has already been scanned
    }
    //check for normal scans - scanned
    if (barcodeType === "SWA") {
      // Check for SWA type barcode
      for(var f=0; f<movement_casks.length; f++) {
        if(doesSWABarcodesMatch(scannedCode,movement_casks[f])) {
          scanned = true;
          break;
        }
      }
    } else {
      // Check for ID type barcode
      scanned = searchList(scannedCode, movement_casks);
    }
    // Now we should know if a scan has been scanned before
    if (scanned) {
      showAlert("This cask has already been scanned", {focus: inputField});
      return true; // Has already been scanned.
    }
  } else {
    // here we can check for incident double scans
    // we do not have the ability to check for older incidents.
    // we do not check for double generated scans as the system makes these
    // todo swa check needs to be different no? It's not ignoring filling.
    if(last_incident.barcode === scannedCode || last_incident.caskid === scannedCode) {
      if(parseInt(last_incident.incidentcode) === parseInt(validationField)) {
        if(!hideDoubleScanError) {
          showAlert("This cask has already been scanned", {focus: inputField});
        }
        return true; // Has already been scanned.
      }
    }
  }
  return false;
}

/**
 * @description Tests to see that every character in a string matches a Regex Expression
 * @param {String} testString
 * @param {string} expression RegEx expression
 * @returns {boolean}
 */
function isValidCharacters(testString, expression) {
	"use strict";
  var testArray = testString.split("");
  var validExpression = new RegExp(expression);

  for (var x = 0; x < testArray.length; x++) {
    if (!validExpression.test(testArray[x])) {
      return false;
    }
  }
  return true;
}

/**
 * @description Tests to see if the testString matches the given RegEx expression
 * @param {String} testString
 * @param {String} expression RexEx Expression
 * @returns {boolean}
 */
function isValidString(testString, expression) {
	"use strict";
  var testExpression = new RegExp(expression);
  return testExpression.test(testString);
}

/**
 * @description validates an entered batch against a batch mapping. If no mapping exists or "" then returns true.
 * @param {string} batchString the string value from a batch input element.
 * @returns {boolean} result of test.
 */
function isBatchValid(batchString) {
	"use strict";
  var batchMapping = (localStorage.getItem("settings/batchMap")) ? localStorage.getItem("settings/batchMap") : "";
  var pass = true;
  if (batchMapping.length > 0) {
    var testString = batchString.toUpperCase();
    var expression = new RegExp(batchMapping);
    if (!expression.test(testString) || !isValidCharacters(testString,"[a-zA-Z0-9]")) {
      pass = false;
    }
  }
  return pass;
}

/**
 * @description Appends a scanned code with the Wood tracking prefix. will also pad space in between with 0s.
 * @param {string} scannedCode
 * @returns {string}
 */
function prefixWoodLabel(scannedCode) {
	"use strict";
  var woodTrackingMap = localStorage.getItem("settings/woodTrackingMap").split("");
  var woodTrackingPrefix = (localStorage.getItem("settings/woodTrackingPrefix")) ? localStorage.getItem("settings/woodTrackingPrefix") : "";
  return woodTrackingPrefix + padNumber(woodTrackingMap.length - woodTrackingPrefix.length, scannedCode);
}

/**
 * @description Pads a shortened WTL to the correct format to display.
 * @param {String} scannedCode
 * @returns {String}
 */
function formatWoodDisplayLabel(scannedCode) {
	"use strict";
  var woodTrackingMap = localStorage.getItem("settings/woodTrackingMap").split("");
  var woodTrackingPrefix = (localStorage.getItem("settings/woodTrackingPrefix")) ? localStorage.getItem("settings/woodTrackingPrefix") : "";
  return padNumber(woodTrackingMap.length - woodTrackingPrefix.length, scannedCode);
}

/**
 * @description Removes Wood tracking prefix to create a clean code to display.
 * @param {String} scannedCode
 * @returns {string}
 */
function trimPrefixFromWoodLabel(scannedCode) {
	"use strict";
  var woodTrackingPrefix = (localStorage.getItem("settings/woodTrackingPrefix")) ? localStorage.getItem("settings/woodTrackingPrefix") : "";
  return scannedCode.substring(woodTrackingPrefix.length);
}

/**
 * @description Using regular expressions removes WT prefix from a string if present
 * @param {String} caskId the string to be checked
 * @returns {String} The trimmed string / cask ID number value
 */
function getCaskIdValue(caskId) {
  "use strict";
  var prefix = localStorage.getVariable("settings/woodTrackingPrefix");
  if (prefix === "") {
    return caskId;
  }
  var regString = "^("+prefix+")";
  return caskId.replace(new RegExp(regString),"");
}
/**
 * @typedef {object} incidentCodes Incident Codes used to report errors
 * @property {Number} incidentCode Unique identification number - client specific
 * @property {String} description Label / description of the incident
 * @property {String} name System identifying name
 * @property {Boolean} casualty Defines if the incident code is used in a manual incident screen
 * @property {Boolean} restricted Defines if authorisation is required for the given incident
 * @property {String} type The type of incident code as a comma delimited list. Valid values in list: CASK,LOCATION.
 */

/**
 * @description Takes any form of caskID and converts it to its associated mapping. Will add prefix if setup in system
 * @param {String} scannedCode Code to be checked and transformed
 * @param {String} mapping the mapping found from the custom format
 * @returns {String} The transformed string
 */
function formatIdMapping(scannedCode,mapping) {
  "use strict";
  var caskId = scannedCode;
  // Trim scanned code to mapping length if necessary
  if (caskId.length > mapping.length) {
    caskId = caskId.subString(0,mapping.length);
  }
  // Here the mapping must equal length or be shorter
  // If equal then return the value scanned I.E. nothing to add or remove.
  if (caskId.length === mapping.length) {
    return caskId;
  }
  //Code must be shorter
  //find a prefix in mapping and apply if appropriate
  var prefix = localStorage.getVariable("settings/woodTrackingPrefix");
  var regString = "^[S]{" + prefix.length + "}";
  var prefixReg = new RegExp(regString);
  var hasPrefixInMapping = prefixReg.test(mapping);
  return ((hasPrefixInMapping)? prefix:"") + padNumber((mapping.length-prefix.length),caskId);
}

/**
 * @desc  Validates SWA details to ensure valid information has been supplied.
 * @param make
 * @param fillYear
 * @param rotation
 * @param caskNumber
 * @param fillLocation
 */
function validateCaskDetails(make, fillYear, rotation, caskNumber, fillLocation) {
	"use strict";
  // if any field is missed in the mapping below then this validation will fail.
  // needed to determine if the value is required by the barcode mapping.
  // Deactivates manual entry validation (field shouldn't be there anyway)
  var mapping = getBarcodeMapping();
  // validate against makes
  if (!validateMake(make)) {
    showAlert("Invalid Make.", {focus: "manualMake"});
    return false; // not a valid make.
  }
  // validate fill year
  if (!isNumeric(fillYear)) {
    showAlert("Invalid Fill Year", {focus: "manualYear"});
    return false;
  }
  // validate rotation number. can be 0, needs padding
  if (mapping.rotation.length > 0 && !isNumeric(rotation)) {
    showAlert("Invalid Rotation Number", {focus: "manualRotation"});
    return false;
  }
  // validate cask no. cannot be 0, needs padding
  if (!isNumeric(caskNumber) || parseInt(caskNumber) === 0) {
    showAlert("Invalid Cask Number", {focus: "manualCask"});
    return false; // if not a number or = 0
  }
  // validate the fill location - length only as we dont care for the value. Blank fill location allowed
  if(mapping.fillLocation.length > 0 && (mapping.fillLocation.length !== fillLocation.length) && fillLocation !== "") {
    showAlert("Invalid Fill Location", {focus: "manualFillLocation"});
    return false; // not the correct length
  }
  return true;
}

/**
 * @description Creates a SWA style barcode mapping object.
 * @returns {{batch: {start: number, length: number, string: string}, make: {start: number, length: number, string: string}, fillYear: {start: number, length: number, string: string}, rotation: {start: number, length: number, string: string}, caskNumber: {start: number, length: number, string: string}, fillLocation: {start: number, length: number, string: string}}}
 */
function getBarcodeMapping() {
  "use strict";
  var barcodeMap = localStorage.getItem("settings/classicBarcodeMapping").split("");
  //Note that Z is the ignored character

  var mapping = {
    batch: {
      start: 0,
      length: 0,
      string: ""
    },
    make: {
      start: 0,
      length: 0,
      string: ""
    },
    fillYear: {
      start: 0,
      length: 0,
      string: ""
    },
    rotation: {
      start: 0,
      length: 0,
      string: ""
    },
    caskNumber: {
      start: 0,
      length: 0,
      string: ""
    },
    fillLocation: {
      start: 0,
      length: 0,
      string: ""
    },
    fillerCharacters: {
      // filler characters only have length as they appear everywhere
      length: 0
    }
  };

  for (var i = barcodeMap.length; i >= 0; i--) {
    switch (barcodeMap[i]) {
      case "B":
        mapping.batch.string += "B";
        mapping.batch.length++;
        mapping.batch.start = i;
        break;
      case "M":
        mapping.make.string += "M";
        mapping.make.length++;
        mapping.make.start = i;
        break;
      case "Y":
        mapping.fillYear.string += "Y";
        mapping.fillYear.length++;
        mapping.fillYear.start = i;
        break;
      case "R":
        mapping.rotation.string += "R";
        mapping.rotation.length++;
        mapping.rotation.start = i;
        break;
      case "C":
        mapping.caskNumber.string += "C";
        mapping.caskNumber.length++;
        mapping.caskNumber.start = i;
        break;
      case "F":
        mapping.fillLocation.string += "F";
        mapping.fillLocation.length++;
        mapping.fillLocation.start = i;
        break;
      case "N":
        mapping.fillerCharacters.length ++;
        break;
    }
  }
  return mapping;
}

/**
 * @description  Creates a barcode from SWA data entered in manual cask entry screen.
 * @param {string} blend
 * @param {string|number|*} fillYear
 * @param {string|number|*} rotation
 * @param {string|number|*} caskNumber
 * @param {string|number|*} fillLocation
 */
function dataToBarcode(blend, fillYear, rotation, caskNumber, fillLocation) {
	"use strict";
  var mapping = getBarcodeMapping();
  var barcode = localStorage.getItem("settings/classicBarcodeMapping");
  if (fillLocation === "") fillLocation = "ZZ";
  // if manual entry was not enough characters then strings need to be padded out
  // TODO what if the Make code is too long for the mapping?
  // if statements to handle for where mapping does not contain the standard values. SWA should never have this issue.
  barcode = replaceMappingValue(mapping.make,padString(mapping.make.length,blend),barcode);
  barcode = replaceMappingValue(mapping.fillYear,fillYear,barcode);
  barcode = replaceMappingValue(mapping.rotation,padNumber(mapping.rotation.length, rotation),barcode);
  barcode = replaceMappingValue(mapping.caskNumber,padNumber(mapping.caskNumber.length, caskNumber),barcode);
  barcode = replaceMappingValue(mapping.fillLocation,fillLocation,barcode);

  return barcode;
}

/**
 * @description replaces a barcode mapping field with scanned or manual entry values. If the mapping object length is 0, then original barcode value is returned.
 * @param mappingValueObject - A mapping field object such as make or caskNumber
 * @param replacementString - The value entered from manual entry
 * @param barcode - The working barcode value to replace values in
 * @returns {String} the resulting barcode
 */
function replaceMappingValue(mappingValueObject,replacementString,barcode) {
	"use strict";
  if(mappingValueObject.length > 0) {
    return barcode.substring(0,mappingValueObject.start) + replacementString + barcode.substring(mappingValueObject.start + mappingValueObject.length,barcode.length);
  } else {
    return barcode;
  }
}

// todo this will be depreciated once we get all scans from regex and allocations come with spirit details
/**
 * @description Converts a barcode to an SWA data object
 * @param {String} barcode
 * @returns {{}}
 */
function barcodeToData(barcode) {
  "use strict";
  var mapping = getBarcodeMapping();
  var data = {};

  data.batch = barcode.substr(mapping.batch.start, mapping.batch.length).toUpperCase();
  data.make = barcode.substr(mapping.make.start, mapping.make.length).toUpperCase();
  data.fillYear = barcode.substr(mapping.fillYear.start, mapping.fillYear.length);
  data.rotation = barcode.substr(mapping.rotation.start, mapping.rotation.length);
  data.caskNumber = barcode.substr(mapping.caskNumber.start, mapping.caskNumber.length);
  data.fillLocation = barcode.substr(mapping.fillLocation.start, mapping.fillLocation.length);

  return data;
}

/**
 * @description Displays the details of the last barcode scanned to the page. Used in labelling only.
 * @param barcodeObject
 */
function displayLastScan(barcodeObject) {
	"use strict";
  var useSWA = Boolean(localStorage.getItem("settings/useSWA") === "true");
  var thisPage = getCurrentPage(),
    trailerInfo;

  //todo this all needs simplified. mode to UI?

  if (!firstCaskScanned && (thisPage === "dsmovements" || thisPage === "dslabel")) {
    var parameters = getProgramFields().split(",");
    if (parameters.length > 1 || parameters[0] !== "") {
      // disable exit
      $('#exitbutton').attr("disabled", "disabled");
      // change button
      $('#changeLocationButton').html("Complete Location");
      firstCaskScanned = true;
    }
  }

  if (useSWA || isPickingProgram()) { // if using swa or program is picking
    var barcodeMap = getBarcodeMapping(),
        bodyCode = '<tr>';

    if (useSWA) {
      // show swa info
      if (barcodeObject.type === "SWA" || barcodeObject.type === "CUSTOM") {
        if (barcodeMap.batch.length > 0) bodyCode += '<td>' + barcodeObject.swa.batch + '</td>';
        if (barcodeMap.make.length > 0) bodyCode += '<td>' + limitString(barcodeObject.swa.make, 9) + '</td>';
        if (barcodeMap.fillYear.length > 0) bodyCode += '<td>' + barcodeObject.swa.fillYear + '</td>';
        if (barcodeMap.rotation.length > 0) bodyCode += '<td>' + barcodeObject.swa.rotation + '</td>';
        if (barcodeMap.caskNumber.length > 0) bodyCode += '<td>' + barcodeObject.swa.caskNumber + '</td>';
      } else {
        // workaround if WTL is also on and scanned.
        if (barcodeMap.batch.length > 0) bodyCode += '<td></td>';
        if (barcodeMap.make.length > 0) bodyCode += '<td></td>';
        if (barcodeMap.fillYear.length > 0) bodyCode += '<td></td>';
        if (barcodeMap.rotation.length > 0) bodyCode += '<td></td>';
        bodyCode += '<td>' + barcodeObject.displayCode + '</td>';
      }

      if (localStorage.getItem("scanTrailer")) {
        if (isCaskOnTrailer(localStorage.getItem("scanTrailer"), barcodeObject)) {
          trailerInfo = deductTrailerContent(localStorage.getItem("scanTrailer"));
          $("#trailerlabel").html(trailerInfo.content + '/' + trailerInfo.oldContent);
        }
      }

    } else {
      // not SWA
      if (isPickingProgram()) {
        // WTL with SWA detail (Make batch and tag) available
        if (barcodeMap.batch.length > 0) bodyCode += '<td>' + barcodeObject.swa.batch + '</td>';
        if (barcodeMap.make.length > 0) bodyCode += '<td>' + limitString(barcodeObject.swa.make,9) + '</td>';
        bodyCode += '<td>' + barcodeObject.displayCode + '</td>';

      } else {
        // WTL without SWA detail available
        bodyCode += '<td>' + barcodeObject.displayCode + '</td>';
      }
    }

    bodyCode += '</tr>';
    $("#casktablebody").html(bodyCode);
  } else {
    var extraField = "";
    if (localStorage.getItem("scanTrailer")) {
      if (isCaskOnTrailer(localStorage.getItem("scanTrailer"), barcodeObject)) {
        trailerInfo = deductTrailerContent(localStorage.getItem("scanTrailer"));
        extraField = '<td>' + localStorage.getItem("scanTrailer") + ' (' + trailerInfo.content + '/' + trailerInfo.oldContent + ')' + '</td>';
      } else {
        // change request no alert to be shown - alert("This cask was not on the trailer", false, "scanInput");
        trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
        extraField = '<td>' + localStorage.getItem("scanTrailer") + ' (' + (parseInt(localStorage.getItem("scanTrailerContent")) - trailerInfo.content) + '/' + localStorage.getItem("scanTrailerContent") + ')' + '</td>';
      }
    }
    // todo does this work with barcode formats?
    $("#casktablebody").html("<tr><td>" + padNumber(7, (barcodeObject.type === "SWA") ? barcodeObject.swa.caskNumber : barcodeObject.displayCode) + "</td>" + extraField + "</tr>");
  }
  $("#scansField").html(movement_casks.length);
}

/**
 * @description Validates stow transactions against a set Rack limit
 * @returns {boolean} True if the current count of transactions is below or equal to the limit
 */
function validateRackLimit() {
	"use strict";
  if (rackLimit && getProgramWarehouseType() === "RACK" && getProgramTransactionCode().toUpperCase() === "STOW") {

    var scannedCasks = movement_casks.length;
    scannedCasks++;

    if (scannedCasks === parseInt(localStorage.getItem("settings/rackLimitWarning"))) {
      showAlert(scannedCasks + " casks have been scanned into this level.", {focus: "scanInput", type: "info"});
      return true;
    }
    if (scannedCasks === parseInt(localStorage.getItem("settings/rackLimitLevel"))) {
      showAlert("Maximum casks (" + scannedCasks + ") have now been scanned into this level.", {
        focus: "scanInput",
        type: "info"
      });
      return true;
    }
    if (scannedCasks > parseInt(localStorage.getItem("settings/rackLimitLevel"))) {
      showAlert("Maximum casks (" + scannedCasks + ") have already been scanned into this level.", {focus: "scanInput"});
      return false;
    }
  }
  return true;
}

/**
 * @description Processes scanned barcode, validates, and saves. The screen is then updated, transactions may be sent. Being Deprecated. Only used for Rick labelling now.
 * @param {String} inputCode the input string from scanning.
 * @returns {boolean} False if any errors are encountered, otherwise true.
 */
function processScan(inputCode, callback) {
	"use strict";
  // function called when a cask is scanned from a scanning input
  var errorStatus,
      validInAllocation,
      barcodeObject = createBarcodeObject(inputCode, "scanInput");
  if (!barcodeObject.passed) {
		callback(false);
    return false;
  }

  if (!validateRackLimit()) {
		callback(false);
    return false;
  }

	if(isLocationCapacityBreached()) {
		callback(false);
		return false;
	} // Checking to see if the warehouse capacity has been breeched for the scanLocation.

  // if picking program validate cask against allocation.
  if (isPickingProgram()) {
    validInAllocation = false;
    errorStatus = isCaskValidInAllocation(barcodeObject);
    if (errorStatus) {
      if (errorStatus.stopProgram) return false;
      validInAllocation = (errorStatus.name !=="notOnAllocation");
    } else {
      validInAllocation = true;
    }
  }

  //todo move to display routine - this shows alerts here and then, updates content, and updates label.
  if (hasProgramParameter("TRAILER")) {
    if (isTrailerFull(localStorage.getItem("scanTrailer"))) {
      showAlert("The trailer Should be full.", {type: "info"});
    }
    var newTrailerInfo = updateTrailerContent(localStorage.getItem("scanTrailer"));
    if (newTrailerInfo.content === newTrailerInfo.capacity) {
      showAlert("Trailer should now be full.", {type: "info"});
    }
    $("#trailerlabel").html(localStorage.getItem("scanTrailer") + ' (' + newTrailerInfo.content + '/' + newTrailerInfo.capacity + ')');
  }

  //save transaction. If true then update displays, otherwise nothing happens.
	saveGenericTransaction(barcodeObject, errorStatus, "", function (success) {
        if (success) {
            //send if allowed
            if (doesProgramSendTransactions()) {
                sendTransactions(false);
            }

            if (!errorStatus) {
                playSuccessSound(true);
            }

            //output details to the screen.
            displayLastScan(barcodeObject);
            if (validInAllocation) {
                updateAllocationCounter();
            }

            //final catch - if we have disgorged and have an error status, then now that the transaction has sent we return false
            //for the manual popup
            callback(!(errorStatus && getProgramTransactionCode().toUpperCase() === "DISGORGE"));
            return !(errorStatus && getProgramTransactionCode().toUpperCase() === "DISGORGE");
        } else {
            callback(false);
            return false; // For the manual popup to know not to close.
        }
    });
}
/**
 * @description Checks if on the login page
 * @returns {boolean} True if on login page
 */
function isLoginPage() {
  "use strict";
  return getCurrentPage() === "dslogin";
}

/**
 * @description Returns the name of the current page, minus the extension
 * @returns {String}
 */
function getCurrentPage() {
  "use strict";
  return window.location.pathname.substring(1).split(".")[0];
}


String.prototype.capitalize = function() {
	"use strict";
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/**
 * @callback successCallback
 * @param {Boolean} success If the request is successful.
 */

/**
 * @callback successCallbackWithCode
 * @param {Boolean} success If the request is successful.
 * @param {Number} HTTP status code.
 */

/**
 * @typeDef {Object} barcodeFormat
 * @property {String} formatCode
 * @property {String} barcodeType
 * @property {String} expression
 * @property {String} expressionGroups
 * @property {Boolean} scotchVariant
 * @property {String} prefixData
 * @property {Number} paddedLength
 */
